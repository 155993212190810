import React from 'react';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import SliceBody from '../components/SliceBody';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import PostHero from '../components/blog/PostHero';
import PrivateContent from '../components/shared/PrivateContent';


class StandalonePage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page("Standalone", {
      title: `${this.props.data.prismicStandalonePage.data.title} | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const data = props.data;
    const pageData = props.data.prismicStandalonePage.data;
    const pageDataRaw = JSON.parse(props.data.prismicStandalonePage.dataString);


    return (
      <Layout reverseLogo={true}>
        <Helmet>
          {titleTags(`${pageData.title} | ${data.prismicSite.data.name}`)}
          {descriptionTags(pageData.meta_description)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <PostHero
          image={pageData.hero_image}
          title={pageData.title}
          compact
        />
        {pageDataRaw.password ? (
          <PrivateContent
            password={pageDataRaw.password}
          >
            <SliceBody
              postData={pageDataRaw}
            />
          </PrivateContent>
        ) : (
          <SliceBody
            postData={pageDataRaw}
          />
        )}
      </Layout>
    );
  }
}


export default StandalonePage;

export const query = graphql`
  query($slug: String!) {
    prismicSite {
      data {
        name
      }
    }
    prismicStandalonePage(uid: {eq: $slug}) {
      uid
      dataString
      data {
        title
        hero_image {
          alt
          copyright
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              
            }
          }
        }
        meta_description
      }
    }
  }
`;