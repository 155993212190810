import React from 'react';
import Facebook from '../../assets/icons/Facebook.svg';
import Twitter from '../../assets/icons/Twitter.svg';
import LinkedIn from '../../assets/icons/LinkedIn.svg';
import styled from 'styled-components';
import { Location } from '@reach/router';


const Icon = styled.div`

`;

const Container = styled.div`
  display: flex;
  margin: -15px;
  
  ${Icon} {
    display: inline-block;
    padding: 15px;
  }
`;

const SocialShareIcons = (props) => (
  <Location>
    {({ location }) => (
      <Container {...props}>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}>
            <img
              alt={'Facebook Icon'}
              src={Facebook}/>
          </a>
        </Icon>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`https://twitter.com/home?status=This%20is%20an%20interesting%20read%0A${location.href}`}>
            <img
              alt={'Twitter Icon'}
              src={Twitter}/>
          </a>
        </Icon>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`https://www.linkedin.com/shareArticle?url=${location.href}`}>
            <img
              alt={'LinkedIn Icon'}
              src={LinkedIn}/>
          </a>
        </Icon>
      </Container>
    )}
  </Location>
);

export default SocialShareIcons;