import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import FluidImg from '../shared/FluidImg';
import { rtypeN21bu, rtypeT84sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { rgba } from 'polished';
import SocialShareIcons from '../shared/SocialShareIcons';
import moment from 'moment';


const Title = styled.h1`
  ${rtypeT84sb};
`;

const Date = styled.div`
  ${rtypeN21bu};
`;

const Category = styled.div`
  ${rtypeN21bu};
`;

const Author = styled.div`
  ${rtypeN21bu};
`;

const Image = styled.div`
  position: relative;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${rgba(colors.black, 0.16)};
  }
  .gatsby-image-wrapper {
    height: 100%;
    z-index: 0;
  }
`;

const CustomShareIcons = styled(SocialShareIcons)`
`;

const CustomContainer = styled(Container)`
  color: ${colors.white};
  grid-template-rows: 120px auto auto auto auto auto 60px;
  
  ${Image} {
    grid-row: 1 / span 7;
    grid-column: 1 / span 10;
    overflow: hidden;
    z-index: -1;
    max-height: 450px;
  }
  
  ${Category} {
    grid-row: 2;
    grid-column: 2 / span 8;
    z-index: 1;
  }
  
  ${Author} {
    grid-row: 3;
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }
  
  ${Title} {
    grid-row: 4;
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }
  
  ${CustomShareIcons} {
    grid-row: 5;
    grid-column: 2 / span 8;
    margin-bottom: 15px;
  }
  
  ${Date} {
    grid-row: 6;
    grid-column: 2 / span 8;
  }

  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 360px auto auto auto 100px;
    align-items: flex-start;
    ${Image} {
      grid-row: 1 / span 5;
      grid-column: 1 / span 10;
      overflow: hidden;
      z-index: -1;
      max-height: ${props => props.compact ? '600px' : '800px'};
      height: 100%;
    }
    ${Category} {
      grid-row: 2;
      grid-column: 2 / span 2;
      margin-bottom: 30px;
    }
    ${Author} {
      grid-row: 2;
      grid-column: 4 / span 3;
      margin-bottom: 30px;
    }
    ${Title} {
      grid-row: 3;
      grid-column: 2 / span 6;
      margin-bottom: 30px;
    }
    ${CustomShareIcons} {
      grid-row: 4;
      grid-column: 2 / span 2;
    }
    ${Date} {
      grid-row: 4;
      grid-column: 4 / span 2;
    }
  }
`;


const PostHero = ({ image, title, category, author, date, showShareIcons, compact, ...props }) => (
  <CustomContainer compact={compact}>
    <Image>
      <FluidImg image={image}/>
    </Image>
    {category && <Category>{category}</Category>}
    {author && <Author>By {author}</Author>}
    <Title>{title}</Title>
    {showShareIcons && <CustomShareIcons/>}
    {date && <Date>{moment(date).format('DD MMMM YYYY')}</Date>}
  </CustomContainer>
);

export default PostHero;